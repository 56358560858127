import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import Router from 'next/router'
import { ThemeProvider } from 'theme-ui'
import dynamic from 'next/dynamic'

// Error handling
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'

import '@styles/global.css'
import '@styles/nprogress.css'
import '@styles/container.css'
import '@reach/dialog/styles.css'
import '@reach/accordion/styles.css'

import theme from '@styles/theme'
import useUIStore, { UIStore } from '@stores/ui'
import useHistoryStore, { HistoryStore } from '@stores/history'
import useAnalyticsStore, { AnalyticsStore } from '@stores/analytics'
import { SessionProvider, ProfileProvider } from '@api/session'
import { AnalyticsProvider } from '@components/AnalyticsProvider'
import { useDisableViewLimit } from '@hooks/useDisableViewLimit'
import PlaceholderPage from './placeholder'

const selectToggleModal = (state: UIStore) => state.actions.toggleModal
const selectAnalyticsEnabled = (state: AnalyticsStore) => state.enabled
const selectSetPreviousPath = (state: HistoryStore) =>
  state.actions.setPreviousPath

const DynamicProgressBar = dynamic(() => import('../components/ProgressBar'), {
  ssr: false,
})

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  })
}

const domain = (url: string) => {
  return url.replace('http://', '').replace('https://', '').split('/')[0]
}

const isRemote = (url: string) => {
  if (typeof window === 'undefined') return true

  const knownDomains = [
    domain(url),
    'cinekid.nl',
    'cinekidplay.nl',
    'staging.cinekidplay.nl',
    'accounts.cinekidplay.nl',
    'accounts-staging.cinekidplay.nl',
  ]
  return knownDomains.indexOf(domain(window.location.href)) === -1
}

export default function App({ pageProps: { authorize } }: AppProps) {
  const isTracking = useAnalyticsStore(selectAnalyticsEnabled)
  const toggleModal = useUIStore(selectToggleModal)
  const setPreviousPath = useHistoryStore(selectSetPreviousPath)
  useDisableViewLimit()

  useEffect(() => {
    const clickIntercept = (e: MouseEvent) => {
      if (!(e.target || ({} as any)).closest) return
      const target = e?.target as Element
      const origin = target.closest('a')

      if (!origin) return
      if (isRemote(origin.href) && !origin.hasAttribute('data-external')) {
        e.preventDefault()
        e.stopPropagation()

        Router.push(`/extern?url=${encodeURIComponent(origin.href)}`)
      }
    }

    document.addEventListener('click', clickIntercept)

    return () => {
      document.removeEventListener('click', clickIntercept)
    }
  }, [])

  useEffect(() => {
    const routeChangeStart = () => {
      toggleModal(undefined)
    }

    const routeChangeComplete = () => {
      setPreviousPath(Router.asPath)
    }

    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [toggleModal, setPreviousPath])

  return (
    <SessionProvider>
      <AnalyticsProvider enabled={isTracking}>
        <ProfileProvider enabled={authorize}>
          <ThemeProvider theme={theme as any}>
            <PlaceholderPage />
            <DynamicProgressBar />
          </ThemeProvider>
        </ProfileProvider>
      </AnalyticsProvider>
    </SessionProvider>
  )
}
