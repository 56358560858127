import { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'

import useUIStore from '@stores/ui'

const DISABLE_VIEW_COUNT_SECRET =
  process.env.NEXT_PUBLIC_DISABLE_VIEW_COUNT_SECRET

export const useDisableViewLimit = () => {
  const setViewLimitDisabled = useUIStore(
    (state) => state.actions.setViewLimitDisabled
  )
  const isRedirected = useRef(false)
  const { replace, pathname, query } = useRouter()
  const disableViewCount =
    typeof query.disableViewCount === 'string'
      ? query.disableViewCount
      : undefined

  useEffect(() => {
    if (
      isRedirected.current ||
      !DISABLE_VIEW_COUNT_SECRET ||
      disableViewCount !== DISABLE_VIEW_COUNT_SECRET
    )
      return

    setViewLimitDisabled()
    replace(pathname)

    isRedirected.current = true
  }, [replace, pathname, disableViewCount, setViewLimitDisabled])
}
