import create from 'zustand'

export type HistoryStore = {
  previousPath?: string
  actions: {
    setPreviousPath: (path?: string) => void
  }
}

const useHistoryStore = create<HistoryStore>((set) => {
  return {
    previousPath: undefined,
    actions: {
      setPreviousPath(path?: string) {
        set(() => ({ previousPath: path }))
      },
    },
  }
})

export default useHistoryStore
